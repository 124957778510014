// Default imports
import React from "react";
import { useState, useEffect } from 'react';

const KenjoCareer = () => {

    function loadKenjoCareerSite() {
        var kenjoCareerSiteHtml = this.responseText.replace(
            /<a _/g,
            '<a target="_blank" _'
        );
        kenjoCareerSiteHtml = kenjoCareerSiteHtml.replace(
            /href="\//g,
            'href="https://wyden.kenjo.io/'
        );
        kenjoCareerSiteHtml = kenjoCareerSiteHtml.replace(
            '<base href="https://wyden.kenjo.io/">',
            ""
        );
        document.getElementById("kenjoCareerSite").innerHTML =
            kenjoCareerSiteHtml;
    }

    useEffect(() => {

        var kenjoCareerSiteXhr = new XMLHttpRequest();
        kenjoCareerSiteXhr.addEventListener("load", loadKenjoCareerSite);
        kenjoCareerSiteXhr.open("GET", "https://wyden.kenjo.io");
        kenjoCareerSiteXhr.send();
    }, []);

    return (
      <div className="kenjo-career">
        <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
        <div id="kenjoCareerSite"></div>
      </div>
    );
};

export default KenjoCareer;
