import React, { Fragment } from "react";
import ImageSection from "./ImageSection";
import SectionHeader from "./SectionHeader";
import GetInTouchButton from "./GetInTouchButton";
import Section from "./Section";
import ContainerWide from "./ContainerWide";
import ButtonText from "./ButtonText";
import scrollTo from 'gatsby-plugin-smoothscroll';


const ImageSectionsGroupCareer = ({ header, imageSections, button, displayLastButton }) => (
  <Section>
    {header && (
      <SectionHeader header={header} className="image-section-group__header" />
    )}
    {imageSections?.map((imageSectionWrapper, i) => (
      <Fragment key={i}>
        <ImageSection {...imageSectionWrapper} flipped={i % 2 != 1} />
      </Fragment>
    ))}
    <ContainerWide className="d-flex justify-content-center get-in-touch-button">
      <ButtonText variant="red" {...button} onClick={button?.url.startsWith('#') ? () => scrollTo(button?.url, 'center') : ''}>
          {button?.title}
      </ButtonText>
    </ContainerWide>
  </Section>
);

export default ImageSectionsGroupCareer;
