import React, { Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import ButtonText from "../components/ButtonText";
import ContainerWide from "./ContainerWide";
import scrollTo from 'gatsby-plugin-smoothscroll';

const ThreeColumns = ({ data, button }) => (
    <Row className="three-columns__row">
        {data?.map(({ header, text }, index) => (
            <Col
                key={index}
                xs={22}
                lg={8}
                className="three-columns__col"
            >
                <div className="three-columns__content">
                    <h4 className="three-columns__header">{header}</h4>
                    <p className="three-columns__text">{text}</p>
                </div>
            </Col>
        ))}
        <ContainerWide className="d-flex justify-content-center get-in-touch-button mb-0">
            <ButtonText variant="red" {...button}  onClick={button?.url.startsWith('#') ? () => scrollTo(button?.url, 'center') : ''}>
                {button?.title}
            </ButtonText>
        </ContainerWide>
    </Row>
);

export default ThreeColumns;
