// Default imports
import React from "react";

const PageHead = ({
    title,
    text,
    variant,
    topMargin,
    specialStylingForRequestDemoPage,
}) => {
    return title || text ? (
        <div
            className={`page-head ${variant === "white" ? "-white" : ""} ${
                topMargin ? "-top-margin" : ""
            } ${
                specialStylingForRequestDemoPage
                    ? "-special-styling-for-request-demo-page"
                    : ""
            }`}
        >
            {title && (
                <h1
                    data-sal="slide-right"
                    data-sal-duration="800"
                    className="page-head__title"
                >
                    {title}
                </h1>
            )}
            {text && (
                <p
                    data-sal="slide-right"
                    data-sal-duration="800"
                    data-sal-delay="400"
                    className="page-head__text typo-h4"
                >
                    {text}
                </p>
            )}
        </div>
    ) : (
        <></>
    );
};
export default PageHead;
