// Default imports
import React from "react";

import Accordion from "./Accordion";
import GenericSection from "./GenericSection";

const AccordionSection = ({ header, text, content }) => {
  return (
    <GenericSection header={header} text={text}>
      <Accordion content={content} />
    </GenericSection>
  );
};

export default AccordionSection;
