// Default imports
import React, { useState } from "react";
import { ListSpecialContentItem } from "./ListSpecial";

const Accordion = ({ content }) => {
  const [activeItem, setActiveItem] = useState(null);

  const handleItemClick = (id) => {
    setActiveItem(id === activeItem ? null : id);
  };

  return (
    <>
      {content?.map((item, i) => (
        <ListSpecialContentItem
          key={i}
          onClick={() => {
            handleItemClick(i);
          }}
          folded={i !== activeItem}
          {...item}
        />
      ))}
    </>
  );
};

export default Accordion;
