import React from "react";
import ContainerWide from "./ContainerWide";
import ButtonText from "../components/ButtonText";

const GetInTouchButton = () => (
  <ContainerWide className="d-flex justify-content-center get-in-touch-button">
    <ButtonText variant="red" url="#get-in-touch">
      Get in touch
    </ButtonText>
  </ContainerWide>
);

export default GetInTouchButton;
