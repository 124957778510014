// Default imports
import React from "react";
import { Col, Row } from "react-bootstrap";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import WpImage from "../components/WpImage";

const Benefits = ({ header, images, items }) => {
  return (
    <div className="benefits">

      <Col xs="24" lg="8" className="mt-auto mb-auto">
        <h2 className="benefits__header">{header}</h2>
        {items.map((item, index) => (
          <BenefitsItem key={index} {...item} />
        ))}
      </Col>
      <Col
        xs="24"
        lg={{ span: "11", offset: "5" }}
      >
        <div className="benefits__images">
          <Row>
              {images.map((imageItem, index) => (
                <Col xs="24" lg={images.length === 1 ? "24" : "12"}>
                  <WpImage
                    image={imageItem.image}
                    className={`benefits__image -variant-${index + 1}`}
                    key={index}
                  />
                </Col>
              ))}
          </Row>
        </div>
      </Col>
    </div>
  );
};

const BenefitsItem = ({ header, text }) => (
  <>
    <h3 className="benefits__title">{header}</h3>
    <p className="benefits__description">{text}</p>
  </>
);

export default Benefits;
