import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { getCurrentRichContent } from "../helpers/richContentHelpers";

const careerPageTplData = (id) => {
  const data = useStaticQuery(
    graphql`
      {
        allWpPage {
          edges {
            node {
              id
              template {
                ... on WpTemplate_Career {
                  templateName
                  careerRichContent {
                    # SECTION: Page Head
                    pageHead {
                      title
                      text
                    }

                    # SECTION: Open positions
                    openPositions {
                      header
                      emptyText
                      positions {
                        header
                        location
                        text
                        links {
                          type
                          text

                          file {
                            localFile {
                              publicURL
                            }
                          }

                          link {
                            target
                            title
                            url
                          }
                        }
                      }
                    }

                    # SECTION: Benefits
                    benefits {
                      header
                      items {
                        text
                        header
                      }
                    }

                    # SECTION: Images
                    images {
                      image {
                        ...wpImage
                      }
                    }
                    #SECTION: Image section
                    imageSections {
                      imageSection {
                        category
                        title
                        text
                        slug
                        variant
                        image {
                          ...wpImageFluid
                        }
                        items {
                          category
                          title
                          text
                          button {
                            variant
                            link {
                              target
                              title
                              url
                            }
                          }
                        }
                      }
                    }
                    imageSectionsButton {
                      target
                      title
                      url
                    }

                    # SECTION: Text + image
                    textImage {
                      imageSection {
                        category
                        title
                        text
                        slug
                        variant
                        image {
                          ...wpImageFluid
                        }
                        items {
                          category
                          title
                          text
                          button {
                            variant
                            link {
                              target
                              title
                              url
                            }
                          }
                        }
                      }
                    }

                    # SECTION: Three columns
                    threeColumns {
                      header
                      text
                    }
                    threeColumnsButton {
                      target
                      title
                      url
                    }

                    # SECTION: Accordion
                    accordion {
                      text
                      header
                      slug
                      content {
                        ... on WpTemplate_Career_Careerrichcontent_Accordion_Content_Accordion {
                          fieldGroupName
                          header
                          content {
                            ... on WpTemplate_Career_Careerrichcontent_Accordion_Content_Accordion_Content_Text {
                              fieldGroupName
                              header
                              text
                              link {
                                target
                                title
                                url
                              }
                            }
                            ... on WpTemplate_Career_Careerrichcontent_Accordion_Content_Accordion_Content_List {
                              fieldGroupName
                              header
                              textAbove
                              textBelow
                            }
                          }
                        }
                        ... on WpTemplate_Career_Careerrichcontent_Accordion_Content_List {
                          fieldGroupName
                          header
                          textAbove
                          textBelow
                        }
                        ... on WpTemplate_Career_Careerrichcontent_Accordion_Content_Text {
                          fieldGroupName
                          header
                          text
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  return getCurrentRichContent(data, id, "careerRichContent");
};

export { careerPageTplData };
